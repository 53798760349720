const setPlayClass = (video, elements) => {
    elements.forEach((element) => {
        if (video.paused) {
            element.classList.add("paused");
            element.classList.remove("playing");
        } else {
            element.classList.add("playing");
            element.classList.remove("paused");
        }
    });
};

const togglePlayStatus = (video) => {
    if (video.paused) {
        video.play();
    } else {
        video.pause();
    }
};

// Set play rules for the hero video
// Allow user to control whether it plays or not
const videocontainer = document.querySelector(".hp-hero-container");
const video = document.querySelector("#bbx-video");
const herotextcontainer = document.querySelector("#herotextcontainer");

if (videocontainer && video) {
    // set initial play status
    window.onLoad = () => {
        setPlayClass(video);
    };

    // if we have a video, add video-active to container
    // onclick, we set playing/paused on video container & hero container
    // Reason is that other videos will have individual controls - background video doesn't
    videocontainer.classList.add('video-active');
    videocontainer.onclick = () => {
        togglePlayStatus(video);
        setPlayClass(video, [videocontainer, herotextcontainer]);
    };
    
    // same behaviour for the hero text container
    herotextcontainer.classList.add('video-active');
    herotextcontainer.onclick = () => {
        togglePlayStatus(video);
        setPlayClass(video, [videocontainer, herotextcontainer]);
    };
}

// Lazy load videos
document.addEventListener("DOMContentLoaded", function () {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove("lazy");
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }
});


// Play video on scroll
const observeVideo = (video, threshold) => {
    const videoobserver = new IntersectionObserver(function (entries) {
        // isIntersecting is true when element and viewport are overlapping
        // isIntersecting is false when element and viewport don't overlap
    
        entries.forEach(entry => {
            if (entry.isIntersecting === true) {
                // Only play if this video hasn't been paused
                if(!entry.target.classList.contains('paused')){
                    entry.target.play();
                }
            } else if (entry.isIntersecting === false) {
                entry.target.pause();
            }
    
        });
    }, { 
        threshold: (threshold) ? [threshold] : [0.75]  // % of element that must be visible 
    });
    videoobserver.observe(video);
};

document.querySelectorAll("video:not(#bbx-video)").forEach(video => {
    observeVideo(video);
});

const observer = new IntersectionObserver(function (entries) {
    // isIntersecting is true when element and viewport are overlapping
    // isIntersecting is false when element and viewport don't overlap

    entries.forEach(entry => {
        if (entry.isIntersecting === true) {
            entry.target.classList.add('active');

            // reset their position - extra transform on top of the colourise
            entry.target.style.transform = 'translateX(0px)';

        } else if (entry.isIntersecting === false) {
            entry.target.classList.remove('active');
        }

    });
}, {
    threshold: [0.5]  // % of element that must be visible 
});

document.querySelectorAll(".colourise").forEach(image => {
    observer.observe(image);
});

const herotext = document.querySelector('#herotext');
// const herotextcontainer = document.querySelector('#herotextcontainer');

if(herotext){
    const array = herotext.textContent.split(' ');
    herotext.innerHTML = ''; // Clear innerHTML
    array.forEach((item) => {
        herotext.innerHTML += `<div class='word'>${ item }</div>`;
    });
    const words = document.querySelectorAll('#herotext .word');
    
    // hiding
    words.forEach(word => {
        word.style.transform = 'translateX(-2000px)';
        word.style.visibility = 'visible';
    });
    
    // revealing
    let wordCount = 0;
    words.forEach(word => {
        wordCount++;
        setTimeout(() => {
            word.style.transform = 'translateX(0px)';
        }, wordCount * 700);
    
        // // if this is the last iteration, set a timeout for the fadeout
        // if(wordCount === words.length) {
        //     setTimeout(() => {
        //         // making transparent - 
        //         // herotextcontainer.classList.add("fade-out");
        //     }, (wordCount * 1000) + 15000);
        // }
    });
}

import 'slick-carousel';
import $ from 'jquery';

$('.rollinglist').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: false,
    prevArrow: false,

    // the magic
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
        }
    }, {

        breakpoint: 600,
        settings: {
            slidesToShow: 1,
        }
    }]
});
